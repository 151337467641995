.Recipe {

  .my-item {

    .GridItem {
      padding: 0;
    }

    .title {
      font-weight: 900;
      font-size: 18px;
      color: #68F6BE;
      text-align: center;
      padding-top: 17px;
      padding-bottom: 12px;
      border-bottom: 0.5px solid #373D58;
      margin-bottom: 13px;
    }

    .list {
      padding: 0 17px;
      border-bottom: 0.5px solid #373D58;

      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 12px;
        }

        .list-item-title {
          margin-right: 60px;
        }

        .list-item-image {
          display: flex;
          align-items: center;

          img {
            border-radius: 12px;
            object-fit: cover;
          }

          .amount {
            margin-left: 8px;
          }
        }
      }
    }

    .bottom {
      display: flex;
      padding: 25px 17px 18px;

      .timer {
        margin-left: auto;
      }
    }
  }

  .item {
    background: #24293E;
    border-radius: 20px;
    overflow: hidden;

    .image {
      position: relative;

      img {
        object-fit: cover;
        max-height: 250px;
      }

      .description {
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        bottom: 16px;
        left: 19px;

        span {
          font-weight: 900;
          color: #FF8E75;
        }
      }
    }

    .content {
      padding: 19px;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
      }

      .actions {
        display: flex;
        padding-top: 13px;
        align-items: center;
      }
    }
  }
}