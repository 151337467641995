@mixin roundedAmount {
  position: absolute;
  background: #191D2C;
  border: 0.5px solid #29EBCC;
  font-weight: 600;
  font-size: 14px;
  border-radius: 28px;
  color: #fff;
  padding: 4px;
}

.MyIngredients {
  table thead th {
    font-size: 12px;
  }

  table tbody td {
    font-size: 14px;
  }

  .ingredients-list {
    display: flex;
    width: calc(100vw - 32px);
    overflow-x: scroll;
    padding-bottom: 5px;
    margin-top: 30px;
    margin-bottom: 20px;

    .ingredient {
      flex-shrink: 0;
      margin-left: 24px;
      width: 182px;

      &:first-child {
        margin-left: 0;
      }

      .image {
        height: 194px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 0.3px solid #29EBCC;
        box-shadow: 0 20px 31px -20px #000000;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 16px;

        .amount {
          @include roundedAmount;

          top: 11px;
          right: 12px;
        }
      }

      .content {
        margin-bottom: 16px;

        .title {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 3px;
        }

        .description {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }

  table {

    .ingredient {
      display: flex;
      align-items: center;

      .image {
        flex-shrink: 0;
        border: 0.3px solid #7A86A1;
        border-radius: 18px;
        position: relative;

        &.-semitransparent {
          opacity: 0.4;
        }

        img {
          object-fit: cover;
          display: block;
          border-radius: 18px;
        }

        .amount {
          @include roundedAmount;
          top: -3px;
          right: -4px;
        }
      }

      .-dragging .image .amount {
        opacity: 0;
      }

      .content {
        flex: 1;
        padding-right: 20px;
        margin-left: 9px;

        .title {
          margin-bottom: 3px;
        }

        .description {
          position: relative;
          height: 15px;

          & > div {
            font-size: 12px;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
          }
        }
      }
    }
  }
}
