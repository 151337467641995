.Layout {

  &.-login-page {
    background-image: url('../../assets/LoginBackground.png');
    background-size: cover;
    background-position: center;
    min-height: 100svh;

    & > main {
      display: flex;

      & > div {
        display: flex;
      }
    }
  }

  &.-mobile-view {
    //padding-bottom: 80px;
  }

  &.-craft-page {
    min-height: 87vh;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    background-image: url('../../assets/сraftMashine/craft-bg.png');

    @media (-webkit-min-device-pixel-ratio: 2) {
      .img {
        background-image: url('../../assets/сraftMashine/craft-bg@2х.png');
      }
    }
  }

  .container {
  }
}
