.lab-container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
  }
}

.craft-modal {
  position: relative;

  .craft-modal-image {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.31));
    position: absolute;
    top: -110px;
    left: 50%;
    margin-left: -52.5px;
    border: 1px solid #48EBA4;
    border-radius: 100%;

    img {
      object-fit: cover;
      border-radius: 100%;
    }

    .amount {
      font-weight: 600;
      font-size: 13px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #191D2C;
      border: 0.5px solid #48EBA4;
      top: -12px;
      left: 50%;
      margin-left: -15px;
      position: absolute;
    }
  }

  .craft-modal-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 16px;
  }

  .craft-modal-text {
    line-height: 1.5;
    padding-bottom: 20px;
  }
}

.Laboratory {
  min-height: 500px;
  min-width: 540px;
  max-width: 760px;
  margin: 0 auto;
  position: relative;

  .dnd-container {

    .result {
      position: absolute;
      width: 250px;
      height: 250px;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      > .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
        color: #29EBCC;
        .bright {
          color: #4DFFFF;
        }
        &.-crafting {
          animation: rotation 2s infinite linear;
        }
      }
      > .question {
        &.-crafting {
          animation-name: spinner;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
          animation-duration: 2s;
          transform-style: preserve-3d;
        }
      }
      > .crafted {
        display: block;
        width: 160px;
        height: 160px;
        margin: 8px 0 0 8px;
        border-radius: 50%;
      }
    }
  }

  .helper-text {
    position: absolute;
    bottom: 18px;
    left: 18px;
    > .decoration {
      color: #29EBCC;
    }
  }
  .craft-btn {
    position: absolute;
    bottom: 18px;
    right: 18px;
  }
  .clear {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.-failed-craft .result {
    .color-listener {
      //color: #707B94 !important;
      color: #FF8E75 !important;

      & > .bright {
        //color: #707B94 !important;
        color: #FF8E75 !important;
      }
    }
  }
}

@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }
  to
  {
    transform: rotateY(-360deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}