.Cart {
  table thead th {
    font-size: 12px;
  }

  table tbody td {
    font-size: 14px;
  }

  .total {
    margin-top: 20px;
    text-align: right;
    font-size: 14px;
  }

  .buttons {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
  }
}