@import '../../../styles/_mixins';

.QuantityPicker {
  display: inline-flex;

  .controller {
    @extend %resetButton;
    position: relative;
  }

  .screen {
    background: none;
    border: none;
    font-weight: 600;
    color: #fff;
    text-align: center;
    width: 30px;
  }

  &-bordered {
    border: 1px solid #29EBCC;
    border-radius: 20px;

    .controller {
      border: 1px solid #29EBCC;
      border-radius: 20px;
      width: 32px;
      height: 32px;

      &-minus {
        left: -1px;
      }

      &-plus {
        right: -1px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 1.3px;
          height: 15px;
          background: #29EBCC;
          top: 50%;
          margin-top: -7.5px;
          left: 50%;
          margin-left: -0.65px;
          border-radius: 1px;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 15px;
        height: 1.3px;
        background: #29EBCC;
        top: 50%;
        margin-top: -0.65px;
        left: 50%;
        margin-left: -7.5px;
        border-radius: 1px;
      }
    }

    .screen {
      font-size: 12px;
    }
  }

  &-default {

    .controller {
      border: 1.3px solid #fff;
      border-radius: 100%;
      width: 20px;
      height: 20px;

      &-plus {

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 1.3px;
          height: 8px;
          background: #fff;
          top: 50%;
          margin-top: -4px;
          left: 50%;
          margin-left: -0.65px;
          border-radius: 1px;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 1.3px;
        background: #fff;
        top: 50%;
        margin-top: -0.65px;
        left: 50%;
        margin-left: -4px;
        border-radius: 1px;
      }
    }

    .screen {
      font-size: 14px;
    }
  }
}