%resetButton {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}