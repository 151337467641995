.Breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  .breadcrumbs-delimiter {
    position: relative;
    top: -2px;
    margin: 0 10px;
  }

  &.-mobile-view {
    font-size: 25px;

    .breadcrumbs-delimiter {
      width: 6px;
      top: 0;
      margin: 0 6px;
    }
  }

  .item {
    display: inline-flex;
    align-items: baseline;

    .back {
      margin-right: 5px;
    }
  }
}