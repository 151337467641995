.GridItem {
  background: #191D2C;
  border: 0.3px solid #29EBCC;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 18px;
  padding: 28px 32px;
  &.-full-height {
    height: 100%;
  }
  &.-contained {
    background: #22273E;
  }
  &.-transparent {
    background: rgba(25, 29, 44, 0.5);
  }
  &.-contained-tiny {
    border: none;
    background: #22273E;
  }
  &.-gradient {
    border: none;
    background: linear-gradient(180deg, #202E2E 0%, #002F41 97.92%);;
  }
  &.-margin-bottom {
    margin-bottom: 36px;
  }
}
