.avatar-modal {

  &__list {
    display: flex;
    gap: 16px;
  }

  &__item {
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;

    img {
      display: block;
    }

    &--active {
      outline: 3px solid #29EBCC;
    }
  }
}

.Dashboard {

  .staking-card {
    padding: 22px;
    border-radius: 12.375px;
    border: 0.773px solid #29EBCC;

    h6 {
      margin-top: 0;
    }
  }

  &__title {
    color: #AFACAC;
    font-family: Oswald;
    font-size: 74.25px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.2%;
    margin-bottom: 20px;
  }

  &__bottom {
    display: flex;
    gap: 38px;
    padding-top: 20px;

    &-left {
      flex: 1 1 0;

      .banners {
        display: flex;
        gap: 30px;
        margin-bottom: 46px;

        .banner {
          position: relative;
          flex: 1;
          height: 200px;
          border-radius: 12.375px;
          border: 0.773px solid #29EBCC;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), lightgray 46.795px 1.1px / 71.097% 98.592% no-repeat, #C0BE8F;
          background-image: url('../../assets/banner2.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          &__logo {
            position: absolute;
            top: 30px;
            right: 18px;
            width: 130px;
          }

          &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
          }

          &-raffle {
            background-image: url('../../assets/banner3.jpeg');
            background-position: top;

            .banner__info {
              color: #FFF;
              font-family: Oswald;
              font-size: 30.938px;
              font-style: normal;
              font-weight: 500;
              line-height: 123.2%;
              text-transform: uppercase;
            }
          }

          &__info {
            position: absolute;
            top: 10px;
            left: 10px;
            color: #FFF;
            font-family: Oswald;
            font-size: 18.563px;
            font-style: normal;
            font-weight: 500;
            line-height: 123.2%;
            text-align: center;

            span {
              text-align: center;
              color: #FF7714;
              text-align: center;
              font-family: Oswald;
              font-size: 30.938px;
              font-style: normal;
              font-weight: 500;
              line-height: 123.2%;
              display: block;
            }
          }

          &__button {
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .accordion {
        margin-bottom: 30px;

        &__title {
          background: rgba(255, 255, 255, 0.1);
          padding: 9px;
          color: #FFF;
          font-family: Oswald;
          font-size: 24.75px;
          font-style: normal;
          font-weight: 500;
          line-height: 18.949px;
          margin-bottom: 12px;
        }

        &__item {
          border-bottom: 1px solid rgba(217, 217, 217, 0.2);

          &-title {
            color: #FFF;
            font-family: Nunito;
            font-size: 15.469px;
            font-style: normal;
            font-weight: 500;
            line-height: 18.949px;
            padding-top: 6px;
            padding-bottom: 6px;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
            user-select: none;

            svg {
              transition: all 0.3s;
            }
          }

          &-body {
            overflow: hidden;
            max-height: 0;
            color: #A3A3A3;
            font-family: Nunito;
            font-size: 15.469px;
            font-style: normal;
            font-weight: 500;
            line-height: 18.949px;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

            &-text {
              padding-bottom: 10px;
            }
          }

          &--active {
            .accordion__item-body {
              max-height: 1000px;
              transition: max-height 1s ease-in-out;
            }

            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &-right {
      flex: 1 1 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 3px solid rgba(41, 235, 204, 0.3);

    &-left {
      display: flex;
      align-items: center;
      gap: 80px;
      flex: 1 1 0;

      .welcome {
        color: #FFF;
        font-family: Oswald;
        font-size: 46.406px;
        font-style: normal;
        font-weight: 500;
        line-height: 123.2%;

        &__edit {
          display: flex;
          align-items: center;
          gap: 8px;

          &-save {
            width: 30px;
            cursor: pointer;
          }

          &-cancel {
            width: 30px;
            cursor: pointer;
          }
        }

        span {
          color: #65C7F1;
          display: inline-flex;
          align-items: baseline;
          gap: 8px;

          svg {
            cursor: pointer;
          }
        }
      }

      .avatar {
        border: 2px solid rgba(41, 235, 204, 0.3);
        width: 155px;
        height: 180px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 3px;
        position: relative;
        z-index: 2;

        &__edit {
          cursor: pointer;
          position: absolute;
          top: 4px;
          left: 4px;
        }

        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 164px;
          position: absolute;
          top: 10px;
          right: -12px;
          background: linear-gradient(to bottom, #29EBCC, #191C2A);
          border-radius: 0 3px 3px 0;
        }
      }
    }

    &-center {
      flex: 1 1 0;

      .status {
        text-align: center;

        &__label {
          color: #FFF;
          font-family: Oswald;
          font-size: 27.844px;
          font-style: normal;
          font-weight: 400;
          line-height: 123.2%;
        }

        &__value {
          color: #9D0DD3;
          font-family: Oswald;
          font-size: 46.406px;
          font-style: normal;
          font-weight: 500;
          line-height: 123.2%;
          margin-bottom: 6px;
        }

        &__numbers {
          border-radius: 14.695px;
          background: #2A303F;
          padding: 4px 25px;
          display: inline-flex;
          gap: 32px;
          margin-bottom: 6px;
          height: 36px;
          min-width: 106px;

          span {
            color: #FFF;
            font-family: Oswald;
            font-size: 23.844px;
            font-style: normal;
            font-weight: 400;
            line-height: 123.2%;
          }
        }

        &__info {
          color: #FFF;
          font-family: Oswald;
          font-size: 27.844px;
          font-style: normal;
          font-weight: 400;
          line-height: 123.2%;
        }
      }
    }

    &-right {
      flex: 1 1 0;
      display: flex;
      align-items: center;

      .gems {
        text-align: center;

        &__label {
          color: #FFF;
          font-family: Oswald;
          font-size: 27.844px;
          font-style: normal;
          font-weight: 400;
          line-height: 123.2%;
          margin-bottom: 2px;
        }

        &__value {
          color: #5DC2ED;
          font-family: Oswald;
          font-size: 46.406px;
          font-style: normal;
          font-weight: 500;
          line-height: 123.2%;
          margin-bottom: 6px;
          display: inline-flex;
          align-items: center;
          gap: 8px;

          span {
            font-size: 26px;
          }
        }

        &__button {

        }
      }

      .time {
        div {}
      }

      .timezone {
        text-align: center;
        margin-left: auto;

        &__label {
          color: #FFF;
          font-family: Oswald;
          font-size: 27.844px;
          font-style: normal;
          font-weight: 400;
          line-height: 123.2%;
          margin-bottom: 2px;
        }

        &__value {
          font-family: Oswald;
          font-size: 46.406px;
          font-style: normal;
          font-weight: 700;
          line-height: 123.2%;
          margin-bottom: 5px;
          color: #5DC2ED;
        }

        &__utc {
          color: #FFF;
          font-family: Oswald;
          font-size: 27.844px;
          font-style: normal;
          font-weight: 400;
          line-height: 123.2%;
          display: inline-flex;
          gap: 8px;
          align-items: center;

          svg {
            width: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
