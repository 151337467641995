.SlotMachine {
  .bet-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }
  .side-info {
    margin-bottom: 1rem;
  }

  .slot-machine {
    overflow: hidden;
    border-radius: 18px;
    border: 0.3px solid #29EBCC;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    padding: 0 24px;
    position: relative;
    margin: auto;
    max-height: 680px;
    max-width: 700px;

    .indicators {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      left: 0;
      right: 0;

      &::before,
      &::after {
        content: '';
        width: 18px;
        height: 20px;
        display: block;
      }
      &::before {
        background-image: url("../../assets/Rarr.svg");
      }
      &::after {
        background-image: url("../../assets/Larr.svg");
      }

      &.-win-line {
        &::before {
          background-image: url("../../assets/RarrWin.svg");
        }
        &::after {
          background-image: url("../../assets/LarrWin.svg");
        }
      }

      &.-first {
        top: 16%;
      }
      &.-second {
        top: 48%;
      }
      &.-third {
        top: 80%;
      }
    }
  }

  .reels {
    display: flex;
    width: 100vw;
    height: calc((3 / 3) * 100vw + 24px + 24px);
    max-height: calc(90vh - (22px * 3));
    max-width: calc((3 / 3) * (90vh - 50px - 40px));

    .reel {
      width: 33%;
      margin: 0 12px;
      height: 100%;
    }

    &.-animated {
      .slots {
        animation-duration: 4s;
        animation-timing-function: ease;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        $filter: drop-shadow(0px 0px 10px #29EBCC);
      }

      .reel:nth-child(1) .slots{
        animation-name: spin1;
        animation-duration: 3.6s;
      }
      .reel:nth-child(2) .slots{
        animation-duration: 4s;
        animation-name: spin2;
      }
      .reel:nth-child(3) .slots{
        animation-duration: 3.8s;
        animation-name: spin3;
      }
    }

  }

  @keyframes blinking {
    0% {
      filter: drop-shadow(0px 0px 12px #21efce);
    }
    50% {
      filter: drop-shadow(0px 0px 16px #21efce);
    }
    100% {
      filter: drop-shadow(0px 0px 14px #21efce);
    }
  }
  @mixin winner {
    animation-name: blinking;
    animation-iteration-count: infinite;
    animation-duration: 0.4s;

    & > img {
      border: 2px solid #d7e388;
      filter: drop-shadow(0px 0px 6px rgba(#d7e388, 0.8));
    }
  }

  .slots {
    &.-winner-0 {
      .slot:nth-child(1) {
        @include winner;
      }
    }
    &.-winner-1 {
      .slot:nth-child(2) {
        @include winner;
      }
    }
    &.-winner-2 {
      .slot:nth-child(3) {
        @include winner;
      }
    }
  }
  .slot {
    img {
      width: 100%;
      margin: 24px 0;
      height: auto;
      background: #22273E;
      border: 1px solid #C2C2C2;
      border-radius: 18px;
      display: block;
      box-sizing: border-box;

      /* enable gpu accelaration, fixes iOS flicker */
      transform: translate3d(0, 0, 0);
    }
  }
}

@keyframes spin1 {
  100% {
    margin-top: var(--spin1);
  }
}
@keyframes spin2 {
  100% {
    margin-top: var(--spin2);
  }
}

@keyframes spin3 {
  100% {
    margin-top: var(--spin3);
  }
}

.confetti {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
