.NotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  gap: 40px;

  svg {
    animation:spin 16s linear infinite;
    width: 300px;
    height: 300px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}