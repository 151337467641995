.CartCounter {
  position: relative;
  display: inline-flex;

  .value {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 8px;
    height: 20px;
    top: 50%;
    margin-top: -10.5px;
    z-index: 2;
    line-height: 20px;
    padding-left: 2px;
  }
}