.Login {
  display: flex;
  flex-direction: column;
  width: 100%;

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: center;

    @media (max-width: 720px) {
      margin-bottom: 10px;
    }

    .soc-nets {
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        display: flex;
      }
    }

    .copyright {
      color: #FFF;
      font-family: Oswald;
      font-size: 23.261px;
      font-style: normal;
      font-weight: 400;
      line-height: 123.2%;
      margin-top: 9px;

      @media (max-width: 720px) {
        font-size: 16px;
      }
    }
  }

  .frame {
    background-image: url('../../assets/LoginImage.png');
    width: 100%;
    background-position: center;
    background-size: auto;
    position: relative;
    box-shadow: 28px 23px 19.4px 0 rgba(0, 0, 0, 0.30);
    margin-top: 60px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    aspect-ratio: 1.9135135135 / 1;

    @media (max-width: 720px) {
      margin-top: 0;
      background-position: initial;
      background-size: cover;
      box-shadow: none;
      margin-bottom: 0;
    }

    .logo {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        @media (max-width: 720px) {
          width: 200px;
        }
      }
    }

    .button-container {
      margin-top: auto;
      margin-bottom: 80px;

      @media (max-width: 720px) {
        margin-bottom: 50px;
      }
    }

    .button {
      margin: 0 auto;
      border: none;
      outline: none;
      border-radius: 12.153px;
      background: linear-gradient(0deg, #00FF80 0%, #00FF80 100%), #01615D;
      box-shadow: 10px 11px 5.3px 0px rgba(0, 0, 0, 0.26), 0px 0px 46px 0px rgba(0, 255, 128, 0.65), 0px 0px 5.6px 0px rgba(255, 255, 255, 0.58);
      color: #000;
      padding: 22px 60px;
      display: flex;
      font-family: 'Oswald';
      font-size: 46.406px;
      font-style: normal;
      font-weight: 500;
      line-height: 123.2%;
      cursor: pointer;
      transition: opacity 0.3s;
      position: relative;
      z-index: 5;

      @media (max-width: 720px) {
        font-size: 24px;
        padding: 16px 30px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
