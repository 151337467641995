.MenuItem {

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 40px;
    margin: 4px 10px;
    padding: 13px 20px;
    color: #7A86A1;
    transition-property: color, border-color, background;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    position: relative;

    &:hover {
      color: #29EBCC;
      border: 1px solid #29EBCC;
    }

    &.-active {
      background: rgba(#29EBCC, 0.3);
      color: #ffffff;
      cursor: default;

      &::before {
        opacity: 0;
      }
    }

    & > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      & > .icon {
        margin: -1px 16px 0 0;

        & > svg {
          display: block;
        }
      }
    }

    &.-toggled {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      align-items: center;
      border-radius: 0;
      justify-content: center;

      &.-active::before {
        opacity: 1;
        content: '';
        position: absolute;
        background: #29EBCC;
        width: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        left: 0;
        top: 4px;
        bottom: 4px;
      }

      &:hover {
        border-color: transparent;
      }

      & .icon {
        margin: 0;
      }

      & .name {
        display: none;
      }
    }

    &.-mobile-view {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 0;
      align-items: center;
      justify-content: center;

      &.-active {
        background: #266469;
        box-shadow: inset 0 2px 10px rgba(255, 255, 255, 0.25);

        &::before {
          width: 80px;
          height: 80px;
          background: #242A3E;
          position: absolute;
          border-radius: 50%;
          z-index: -1;
          left: -20px;
          top: -20px
        }
      }

      & .icon {
        margin: 0;
      }

      & .name {
        display: none;
      }
    }

    &.-coming-soon,
    &.-coming-soon:hover {
      color: #060E2C;
      cursor: default;
      border-color: transparent;
    }
  }

  .children {
    max-height: 0;
    overflow: hidden;
    transition: max-height .35s;
  }

  .button.-active + .children {
    max-height: 100px;
  }

  .button + .children {

    .button {
      padding: 8px 16px;

      &:not(.-toggled) {
        margin-left: 44px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }

  .button.-opened.-mobile-view + .children {
    position: absolute;
    bottom: 80px;
    margin-left: -8px;
    max-height: none;

    .child {
      margin-bottom: 8px;
    }

    .button {
      width: auto;
      border-radius: 40px;
      justify-content: flex-start;
      background: #242A3E;
      padding-right: 26px;

      .icon {
        margin-right: 8px;
      }

      &.-active {
        background: #1E5A5B;
        box-shadow: none;
      }
      
      &:before {
        display: none;
      }

      .name {
        display: block;
      }
    }
  }
}
