.item-tooltip {
  span {
    display: block;
    margin-bottom: 10px;
    font-weight: normal;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.CraftingStatementItem {
  display: flex;
  align-items: center;

  .image {
    border: 2px solid #FFFA7A;
    border-radius: 12px;
    margin-right: 6px;
    flex-shrink: 0;
    display: inline-flex;
    position: relative;

    img {
      object-fit: cover;
      border-radius: 12px;
      overflow: hidden;
    }

    .amount {
      position: absolute;
      font-weight: 600;
      font-size: 10px;
      width: 23px;
      height: 23px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #191D2C;
      border: 0.5px solid #FFFA7A;
      top: -12px;
      left: 50%;
      margin-left: -11.5px;
    }
  }

  &.-border-yellow {
    .image {
      border-color: #FFFA7A;

      .amount {
        border-color: #FFFA7A;
      }
    }
  }

  &.-border-violet {
    .image {
      border-color: #9d63f4;

      .amount {
        border-color: #9d63f4;
      }
    }
  }

  &.-border-blue {
    .image {
      border-color: #5b90f3;

      .amount {
        border-color: #5b90f3;
      }
    }
  }

  &.-border-green {
    .image {
      border-color: #63F4B9;

      .amount {
        border-color: #63F4B9;
      }
    }
  }

  &.-border-red {
    .image {
      border-color: #FF8E75;

      .amount {
        border-color: #FF8E75;
      }
    }
  }

  .content {

    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-top: 3px;
      max-width: 120px;
    }

    .ellipsis {
      white-space: nowrap;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-size: 12px;
    }
  }

  .plus {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-left: 11px;
    margin-right: 11px;
    flex: 1;
    text-align: center;
  }

  &.-result {

    .image {
      border: 0.3px solid #7A86A1;
    }
  }

  &.-last {
    margin-right: 20px;
  }
}