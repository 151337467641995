.Ingredient {
  background: #24293E;
  border-radius: 20px;
  overflow: hidden;

  .image {
    height: 204px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    //.price {
    //  position: absolute;
    //  left: 20px;
    //  bottom: 20px;
    //  background: rgba(255, 255, 255, 0.3);
    //  backdrop-filter: blur(30px);
    //  border-radius: 8px;
    //  padding: 8px 12px;
    //  color: #fff;
    //
    //  &__label {
    //    font-weight: 700;
    //    font-size: 10px;
    //    line-height: 14px;
    //    margin-bottom: 1px;
    //  }
    //
    //  &__value {
    //    font-weight: 600;
    //    font-size: 14px;
    //    line-height: 19px;
    //  }
    //}
  }

  .counts {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    align-items: flex-end;
    padding-top: 4px;

    .price {

      .label {
        font-weight: 700;
        font-size: 10px;
        text-align: right;
        margin-bottom: 2px;
      }
    }
  }

  .level {
    font-weight: 600;
    font-size: 12px;
  }

  .content {
    padding: 16px 20px;

    .name {
      // todo replace with typography styles
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #fff;
    }

    .row {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .badge {
      background: #154B65;
      border-radius: 50px;
      white-space: nowrap;
      height: 32px;
      line-height: 32px;
      padding: 0 20px;
      font-weight: 600;
      font-size: 12px;
      color: #fff;
      margin-left: auto;
      margin-right: 11px;
    }
  }
}

.detail-info {
  display: flex;

  .description {
    flex: 1;
  }

  .image-container {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image {
    border-radius: 20px;
  }

  &.-mobile-view {
    flex-direction: column;

    .image-container {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}