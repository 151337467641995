.MintProcess {

  .description {
    width: 280px;
    margin-bottom: 60px;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .result {
    position: relative;

    .question {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  .slot {
    padding-left: 90px;
    position: relative;
    margin-bottom: 20px;

    &:first-child {
      margin-bottom: 50px;
    }

    .slot-title {
      position: absolute;
      left: 17px;
      bottom: 34px;
    }
  }
}