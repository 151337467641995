.Raffle {
  padding: 20px;

  .button-container {
    display: flex;
    justify-content: center;
  }

  &.-winner {
    outline: 2px solid #27ebcc;
  }

  .image img {
    width: 100%;
    border-radius: 18px;
  }

  .title {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .top-row {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #7A86A1;
  }

  .live:after {
    content: '';
    display: inline-block;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #fc1a28;
    margin-left: 6px;
  }

  .params {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .param {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      &-label {
        color: #7A86A1;
      }

      &-value {
        display: flex;
        align-items: center;
      }
    }
  }
}
