.LeaderBoard {

  .user {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .action-card {
    margin-bottom: 20px;
  }

  .skeleton-container {
    margin-right: 20px;
  }
}
