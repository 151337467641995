.Header {
  .account-path {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.logout-btn {
  font-size: 12px;
  padding: 12px 16px;
  cursor: pointer;
  transition: .3s all;
  svg {
    margin: 0 8px -2px 0 ;
  }
  &:hover {
    color: #29EBCC;
  }
}
