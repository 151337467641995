.Lottery {
  .image-wrapper {
    position: relative;
    height: 100%;
    img {
      position: absolute;
      width: calc(100% + 20px);
      right: -20px;
      display: block;
      bottom: -86px;
      max-width: 280px;
    }
    &.-mobile-view {
      img {
        width: 60%;
        max-width: 140px;
      }
    }
  }
}
