.Statement {
  display: flex;
  flex-direction: column;
  height: 100%;
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px -1px 0;
    &.-emoji {
      margin-top: -2px;
    }
  }
}
