.Trade {
  
  .ingredients-container {
    &.-mobile-view {
      width: calc(100vw - 32px);
      overflow-x: scroll;

      .track {
        flex-wrap: nowrap;
        overflow-x: scroll;
        width: auto;
        padding-bottom: 20px;
        margin: 0;

        & > div {
          flex-basis: 326px;
          flex-shrink: 0;
          padding: 0;
          margin-left: 16px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .cart-counter {
    position: relative;
    top: 5px;
  }

  //.user-balance {
  //  text-align: right;
  //  position: relative;
  //  top: -8px;
  //  white-space: nowrap;
  //
  //  & > .label {
  //    font-size: 12px;
  //    color: #E0DEE6;
  //  }
  //
  //  .cart-counter {
  //    position: absolute;
  //    right: 0;
  //    top: 70px;
  //  }
  //}
}