:root {
  --scroll-bar-width: 6px;
  --scroll-bar-color: #3E435C;
}

.secondary {
  color: #7A86A1;
}
.primary {
  color: #2FE290;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.squared-list {
  list-style-type: none;
  padding: 0;

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 9px;
      height: 9px;
      background: #fff;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

*:focus {
  outline: 0 !important;
}

// scrollbar styles
html ::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  height: var(--scroll-bar-width);
}

html * {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) transparent;
}

html ::-webkit-scrollbar-track {
  background: transparent;
}

html ::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: var(--scroll-bar-width);
}

html ::-webkit-scrollbar-button:start:decrement,
html ::-webkit-scrollbar-button:end:increment {
  display: none;
}