.CraftingStatement {

  .table-header-cell {
    font-size: 12px;
  }

  .failed {
    font-weight: 600;
    font-size: 14px;
    color: #FF8E75;
  }

  .skeleton-container {
    margin-right: 20px;
    height: 44px;
    display: flex;
    align-items: center;
  }
}