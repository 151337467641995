.DropFlask {
  position: absolute;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #2fe290;
  user-select: none;
  cursor: pointer;

  .bright {
    color: #BEFFFF;
  }

  &.num-1 {
    left: 0;
    top: 100px
  }

  &.num-2 {
    left: 50%;
    transform: translateX(-50%);
    top: 350px;
  }

  &.num-3 {
    right: 0;
    top: 100px;
  }

  &.-hovered {
    .ingredient {
      color: #67EB29;
    }
  }

  // todo not working right now
  &.-cant-drop {
    .ingredient {
      animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  .ingredient {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    color: #29EBCC;
    animation: rotation 2s infinite linear;
    animation-play-state: paused;
  }

  &.-hovered,
  &.-crafting {
    .ingredient {
      animation-play-state: running;
    }
  }

  .image {
    border: 0.3px solid #7A86A1;
    border-radius: 18px;
    position: relative;
    width: 62px;
    height: 62px;
    z-index: 2;
    margin: auto;

    img {
      display: block;
      border-radius: 18px;
    }

    .amount, .minus, .plus {
      position: absolute;
      background: #191D2C;
      border: 0.5px solid #29EBCC;
      font-weight: 600;
      font-size: 12px;
      border-radius: 28px;
      color: #fff;
      padding: 4px;
      right: -3px;
    }

    .amount {
      top: -3px;
    }

    .minus, .plus {
      bottom: -3px;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: rgba(25, 29, 44, 0.73);
      }
    }

    .minus {
      left: -3px;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
