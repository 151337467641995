.Sidebar {

  .terms {
    padding: 8px;
    text-decoration: none;
    color: #7A86A1;
    font-size: 12px;
    display: inline-block;
    margin: auto auto 0;
  }

  .logo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 16px;
    svg:first-child {
      margin-right: 8px;
    }
  }

  &:not(.-opened) {
    .hamburger {
      background: #242A3E;
      margin-right: -40px;
      padding: 4px;
      border-radius: 0 4px 4px 0;
    }
    .logo {
      margin: 16px auto auto;
      position: relative;
      left: -6px;
      svg {
        margin: 0;
      }
    }
  }

  .menu {
    margin-top: 40px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.-mobile-view {
    .terms {
      display: none;
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
      margin-left: -16px;
      margin-top: 0;
    }
    .logo,
    .hamburger {
      display: none;
    }
  }
}